.platformSidebarMenuItemsContainer {
  display: flex;
  height: 62px;
  width: 460px;
  align-items: center;
  margin-left: 32px;
}

.the402navBarLogo {
  height: 24px;
  margin-bottom: 8px;
}

.platformSidebarMenuItemSelected {
  display: flex;
  align-items: center;
  font-family: Neue Haas Unica;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #66D3D5;
  display: flex;
  cursor: pointer;
  height: 24px;
}

.platformSidebarMenuItemUnSelected {
  display: flex;
  align-items: center;
  font-family: Neue Haas Unica;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #787878;
  cursor: pointer;
  height: 24px;
}

.platformSidebarMenuItemUnSelected:hover {
  color: #66D3D5;
}

.platformSidebarMenuText {
  margin-left: 24px;
}
