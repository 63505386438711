.file-upload-container {
  background: #181818;
  box-shadow: 0px 0px 2px #787878;
  border-radius: 5px;
  cursor: pointer;
  height: 250px;
  width: 572px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  color: #FFFFFF;
  flex-direction: column;
  margin-bottom: 16px;
}

.addVideoButton {
  width: 180px;
  height: 42px;
  background: #272729;
  border-radius: 10px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Neue Haas Unica';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}
