.modal-Background {
  background: rgba(1, 1, 1, 0.95);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.welcomeModal {
  display: flex;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 50%;
  position: absolute;
  justify-content: center;
  z-index: 2147483647;
}

.welcomeModalLogoAndDescriptionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}
.the402logo {
  height: 44px;
}

.walletText {
  font-family: Neue Haas Unica;
  color: #A9ADB5;
  font-weight: 400;
  font-size: 18px;
  margin-top: 16px;
}
.welcomeModalContainer {
  display: flex;
  flex-direction: column;
  width: 475px;
  height: 512px;
  background: #121317;
  box-shadow: 0px 0px 4px #787878;
  border-radius: 10px;
}

.welcomeModalHeader {
  font-family: Chakra Petch;
  font-style: normal;
  font-weight: bold;
  font-size: 80px;
  line-height: 120px;
  text-align: center;
  color: #27B2B4;
  background: linear-gradient(94.02deg, #27B2B4 0%, #9CEFF0 100%);
 -webkit-background-clip: text;
 -webkit-text-fill-color: transparent;
}

.welcomeModalHeaderSubText {
  font-family: Chakra Petch;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #787878;
}

.welcomeModalHeaderContainer {
  height: 40px;
}

.welcomeModalWalletSelctionContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 472px;
  align-items: center;
  cursor: pointer;
}

.welcomeModalWalletText {
  color: #FFFFFF;
  font-family: Neue Haas Unica;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
}

.welcomeModalWalletNewWalletButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 432px;
  height: 60px;
  background: #6D33B6;
  border-radius: 10px;
}

.welcomeModalWalletNewWalletButton:hover {
  background: linear-gradient(91.24deg, #6D33B6 -2.96%, #1962EF 97.8%);
}

.welcomeModalWalletImportWalletButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 432px;
  height: 60px;
  background: #333333;
  border-radius: 10px;
  margin-top: 16px;
}

.welcomeModalXButtonContainer {
  width: 475px;
  display: flex;
  justify-content: flex-end;
}

.welcomeModalXButton {
  color: #FFFFFF;
  cursor: pointer;
  padding: 16px;
}
