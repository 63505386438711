.addFundsModal {
  display: flex;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 50%;
  position: absolute;
  justify-content: center;
  z-index: 2147483647;
}

.addFundsContainer {
  display: flex;
  flex-direction: column;
  width: 475px;
  height: 550px;
  background: #121317;
  box-shadow: 0px 0px 4px #787878;
  border-radius: 10px;
}

.addFundsCopyContainer:hover {
  background: #787878;
  color: #FFFFFF;
}

.addFundsHeader {
  font-family: Neue Haas Unica;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 475px;
  text-align: center;
  color: #FFFFFF;
}

.addFundsHeaderSubText {
  font-family: Neue Haas Unica;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #787878;
  width: 475px;
  margin-top: 16px;
  justify-content: center;
}

.addFundsbackedupSelctionContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 220px;
  cursor: pointer;
}

.addFundsbackedupText {
  color: #FFFFFF;
  font-family: Neue Haas Unica;
  font-style: normal;
  font-weight: 800;
  font-size: 26px;
  line-height: 26px;
}


.addFundsbackedupNewbackedupButton:hover {
  background: #27B2B4;
}


.passwordInput {
  padding: 8px;
  width: 328px;
  height: 40px;
  background: #181818;
  box-shadow: 0px 0px 2px #787878;
  border: none;
}

.passwordFormContainer {
  display: flex;
  flex-direction: column;
}

.QRcodeMainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}

.addFundsSubmit {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 432px;
  height: 60px;
  background: #333333;
  border-radius: 10px;
  margin-top: 16px;
  color: #FFFFFF;
  font-family: Neue Haas Unica;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
}

.addFundsSubmit:hover {
  background: #27B2B4;
}

.addFundsAddressContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
  cursor: pointer;
}

.copyIcon {
  width: 23px;
  height:  24px;
  margin-left: 8px;
}

.sentMoneyButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 432px;
  height: 60px;
  background: #6D33B6;
  border-radius: 10px;
  margin-top: 16px;
  cursor: pointer;
}

.sentMoneyButton:hover {
  background: linear-gradient(91.24deg, #6D33B6 -2.96%, #1962EF 97.8%);

}

.sentMoneyButtonText {
  color: #FFFFFF;
  font-family: Neue Haas Unica;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
}

.addFundsHeaderContainer {
  width: 475px;
  display: flex;
  justify-content: flex-end;
}
.addFundsXButton {
  color: #FFFFFF;
  cursor: pointer;
  padding: 16px;
}
