.creatorUpload-Outer-Container {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}

.creatorPortalHeader {
  font-family: 'Neue Haas Unica';
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 40px;
  color: #FFFFFF;
  padding-top: 40px;
  margin-left: 48px;
}

.contentUploadContainer {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  margin-left: 16px;
}

.creatorUpload-MainContainer {
  display: flex;
  margin-top: 90px;
}

.contentUploadHeaderInstructions {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #FFFFFF;
}

.creatorPortalVideoAndCoverPhotoContainer {
  display: flex;
  width: 600px;
}

.content-TitleDescriptionHeader {
  font-family: 'Neue Haas Unica';
  font-style: normal;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #FFFFFF;
  margin-bottom: 16px;
  cursor: pointer;
}

.form-continuation {
  background: #FFFFFF;
  border-radius: 10px;
  display: flex;
  font-weight: 700;
  font-size: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
  width: 600px;
  height: 67px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-left: 48px;
  cursor: pointer;
  margin-top: 32px;
  margin-bottom: 32px;
}

.content-TitleDescriptionContainer {
  display: flex;
  flex-direction: column;
}

.creator-upload-descriptionFormContainer {
  margin-left: 48px;
  font-family: 'Neue Haas Unica';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #A9ADB5;
}
.creator-upload-editionsContainer{
  margin-top: 30px;
  margin-left: 48px;
  font-family: 'Neue Haas Unica';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #A9ADB5;
}
.editions-input{
  background: #5C5C5C;
  width: 597px;
  height: 20px;
  color: #FFFFFF;
  font-family: 'Neue Haas Unica';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
}

input:focus {
  outline: none;
}

textarea:focus {
  outline: none;
  box-shadow: 0px 0px 4px #FFFFFF;
}

.creator-upload-titleFormContainer {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #A9ADB5;
}
