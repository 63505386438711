.videosRow-Container {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 768px) {
  .videosHome-Container {
    background: #121317;
    display: flex;
    height: 100vh;
    border-left: 0.5px solid rgb(47, 51, 54);
  }
}

@media (max-width: 768px) {
  .videosHome-Container {
    background: #121317;
    display: flex;
    height: 100vh;
    margin-left: 72px;
    border-left: 0.5px solid rgb(47, 51, 54);
  }
}

.videosHome-topContainer {
  display: flex;
  flex-direction: column;
}

.videos-featuredAuctionContainer {
  margin-top: 48px;
}

.videoSearchBox {
  display: flex;
  justify-content: center;
  width: 100vw;
}

.videosRow-search {
  width: 240px;
  padding: 10px;
  margin-top: 80px;
  background: #181818;
  box-shadow: 0px 0px 2px #787878;
  border: none;
  border-radius: 5px;
  color: #FFFFFF;;
}

.videosRow-search:focus {
    outline: none;
}
