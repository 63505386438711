.title-input {
  background: #5C5C5C;
  width: 597px;
  height: 47px;
  color: #FFFFFF;
}

.form-container {
  display: flex;
  flex-direction: column;
  color: #FFFFFF;
}
