.videoItem-VideoImage{
  display: flex;
  justify-content: center;
  max-height: calc(100vh - 169px);
  height: 56.25vw;
  min-height: 480px;
  width:  100vw;
  background: #000000;
}

.videoItem-VideoPlayer {
  width: 100vw;
}

.playPauseButtons {
  position: absolute;
  top: 160px;
  left: 160px;
  color: rgba(235, 235, 235, 0.7);
  height: 80px;
  width: 80px;
}

.videoItem-Container {
  display: flex;
  cursor: pointer;
  flex-direction: column;
}
.videoItem-AuctionContents {
  display: flex;
  align-items: center;
  margin-top: 62px;
}

.videoItem-TitleContainer {
  display: flex;
}

.videoItem-TitleFirstPart {
  font-family: Neue Haas Unica;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  color: #FFFFFF;
}

.videoItem-VideoTitle {
  font-family: Neue Haas Unica;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  margin-left: 32px;
  margin-top: 16px;
  color: #FFFFFF;
}

.hypeshot-videoPlayer{
  max-width: 100%;
  max-height: 100%;
}

.videoItem-Header {
  font-family: 'Neue Haas Unica';
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  color: #FFFFFF;
  margin-top: 40px;
  margin-left: 48px;
  display: flex;
  align-items: center;
}

.videoItem-Description {
  margin-top: 8px;
  font-family: 'Neue Haas Unica';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #A9ADB5;
  margin-left: 8px;
}
