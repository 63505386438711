.videosRowItem-MainContainer {
  padding: 16px;
  cursor: pointer;
}

.videosRowThumbnailContainer {
  height: 240px;
  width: 426px;
  background: #000000;
  display: flex;
  justify-content: center;
  box-shadow: 0px 0px 2px #787878;
}

.backgroundBlurredImage {
  width: 100%;
  position:absolute;
  left: 0;
  right: 0;
  top: 64px;
  height: 460px;
}

#blurred {
  filter: blur(100px);
}
.videosRowThumbnail {
  max-width: 100%;
  max-height: 100%;
}
.videosRowItem-Title {
  font-family: Neue Haas Unica;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  margin-top: 8px;
  color: #FFFFFF;
}

.videoItem-VideoCreator {
  font-family: Neue Haas Unica;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #AAAEB6;
  margin-left: 32px;
  margin-top: 8px;
}

.videosRowItem-address {
  font-family: Neue Haas Unica;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #A9ADB5;
}

.videosRowItem-views {
  font-family: Neue Haas Unica;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #A9ADB5;
}

.videosRowItemCopyMessage {
  position: relative;
  top: 16px;
  right: 24px;
  font-family: 'Neue Haas Unica';
  font-style: normal;
  font-size: 8px;
  color: #000000;
}
