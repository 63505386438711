.addFundsButton {
  display: flex;
  width: 148px;
  height: 28px;
  border: none;
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background: #6D33B6;
  border-radius: 5px;
}

.addFundsButtonText {
  display: flex;
  color: #FFFFFF;
  font-family: Neue Haas Unica;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
}

.addFundsButtonContainer {
  display: flex;
  justify-content: center;
  margin-top: 8px;
}
