.signUpButton {
  background: #FFFFFF;
  border-radius: 10px;
  font-weight: 700;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #121317;
  width: 160px;
  height: 42px;
  border-radius: 10px;
  cursor: pointer;
  border: none;
}

.signUpButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
