.description-input {
  background: #181818;
  box-shadow: 0px 0px 2px #787878;
  border-radius: 5px;
  width: 597px;
  height: 193px;
  color: #FFFFFF;
  font-family: 'Neue Haas Unica';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  border: none;
}

.form-container {
  display: flex !important;
  flex-direction: column  !important;
  color: #FFFFFF;
  margin-top: 4px;
}
