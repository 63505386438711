.seedPhraseModal {
  display: flex;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 50%;
  position: absolute;
  justify-content: center;
  z-index: 2147483647;
}

.seedPhraseContainer {
  display: flex;
  flex-direction: column;
  width: 475px;
  height: 512px;
  background: #121317;
  box-shadow: 0px 0px 4px #787878;
  border-radius: 10px;
}

.seedPhraseSecretContainer {
  display: flex;
  justify-content: center;
  background: #181818;
  box-shadow: 0px 0px 2px #787878;
  border-radius: 5px;
  width: 328px;
  height: 105px;
  align-items: center;
}

.seedPhraseCopyMainContainer {
  display: flex;
  justify-content: center;
}

.seedPhraseModalBottomSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 388px;
}

.seedPhraseBoxContainer {
  display: flex;
  justify-content: center;
  margin-top: 32px;
}
.seedPhraseCopyContainer {
  width: 114px;
  height: 32px;
  background: #333333;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #FFFFFF;
  margin-top: 16px;
}

.seedPhraseCopyContainer:hover {
  background: #787878;
  color: #FFFFFF;
}

.seedPhraseCopyText {
  font-size: 14px;
  text-align: center;
  font-family: Neue Haas Unica;
  font-style: normal;
  font-weight: 400;
}

.seedPhraseSecretContainerText {
  font-family: Neue Haas Unica;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #FFFFFF;
}
.seedPhraseHeader {
  font-family: Neue Haas Unica;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 475px;
  text-align: center;
  color: #FFFFFF;
}

.seedPhraseHeaderSubText {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ECD763;
  width: 475px;
  margin-top: 16px;
}

.seedPhrasebackedupSelctionContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.seedPhrasebackedupText {
  color: #FFFFFF;
  font-family: Neue Haas Unica;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
}

.seedPhrasebackedupNewbackedupButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 432px;
  height: 60px;
  background: #333333;
  border-radius: 10px;
  margin-top: 90px;
}

.seedPhrasebackedupNewbackedupButton:hover {
  background: linear-gradient(91.24deg, #6D33B6 -2.96%, #1962EF 97.8%);
  cursor: pointer;
}

.seedPhraseHeaderContainer {
  width: 475px;
  display: flex;
  justify-content: flex-end;
  height: 40px;
}

.seedPhraseModalXButton {
  color: #FFFFFF;
  cursor: pointer;
  padding: 16px;
}
