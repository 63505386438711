.videosRowItem-MainContainer {
    margin-top: 32px;
    cursor: pointer;
  }

  .videosRowThubmnailContainer {
    height: 240px;
    width: 426px;
    background: #000000;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0px 0px 4px #505050;
  }

  .videoRowThubmnail {
    max-height: 100%;
    max-width: 400px;
    object-fit: cover;
  }

  .videosRowItem-Title {
    font-family: Neue Haas Unica;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    margin-top: 8px;
    color: #FFFFFF;
  }

  .videosRowItem-address {
    font-family: Neue Haas Unica;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #A9ADB5;
  }

  .videosRowItem-views {
    font-family: Neue Haas Unica;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #A9ADB5;
  }
