.videosRow-Row {
    display: flex;
    flex-wrap: wrap;
    max-width: 1400px;
    justify-content: center;
}

.videosRow-MainContainer {
  display: flex;
  flex-wrap: wrap;
}
