.importFromPasswordButton {
  display: flex;
  width: 160px;
  height: 42px;
  border: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #181818;
  box-shadow: 0px 0px 2px #A9ADB5;
  border-radius: 10px;
  align-items: center;
  margin-left: 16px;
}

.importFromPasswordButtonText {
  display: flex;
  color: #FFFFFF;
  font-family: Neue Haas Unica;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
}

.importFromPasswordButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
