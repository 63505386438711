@tailwind base;
@tailwind components;
@tailwind utilities;

@import-normalize;
@font-face {
	font-family: Neue Haas Unica;
	src: url("./fonts/neuehaasunica-black.ttf");
	font-weight: 900;
}

@font-face {
	font-family: Neue Haas Unica;
	src: url("./fonts/neuehaasunica-bold.ttf");
	font-weight: bold;
}

@font-face {
	font-family: Neue Haas Unica;
	src: url("./fonts/neuehaasunica-bolditalic.ttf");
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: Neue Haas Unica;
	src: url("./fonts/neuehaasunica-extblkita.ttf");
	font-weight: 950;
	font-style: italic;
}

@font-face {
	font-family: Neue Haas Unica;
	src: url("./fonts/neuehaasunica-extltita.ttf");
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: Neue Haas Unica;
	src: url("./fonts/neuehaasunica-extrablack.ttf");
	font-weight: 950;
}

@font-face {
	font-family: Neue Haas Unica;
	src: url("./fonts/neuehaasunica-extrabold.ttf");
	font-weight: 800;
}

@font-face {
	font-family: Neue Haas Unica;
	src: url("./fonts/neuehaasunica-extrabolditalic.ttf");
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: Neue Haas Unica;
	src: url("./fonts/neuehaasunica-extralight.ttf");
	font-weight: 200;
}

@font-face {
	font-family: Neue Haas Unica;
	src: url("./fonts/neuehaasunica-italic.ttf");
	font-style: italic;
}

@font-face {
	font-family: Neue Haas Unica;
	src: url("./fonts/neuehaasunica-medium.ttf");
	font-weight: 500;
}

@font-face {
	font-family: Neue Haas Unica;
	src: url("./fonts/neuehaasunica-mediumitalic.ttf");
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: Neue Haas Unica;
	src: url("./fonts/neuehaasunica-regular.ttf");
}

@font-face {
	font-family: Neue Haas Unica;
	src: url("./fonts/neuehaasunica-thin.ttf");
	font-weight: 100;
}

@font-face {
	font-family: Neue Haas Unica;
	src: url("./fonts/neuehaasunica-thinitalic.ttf");
	font-weight: 100;
	font-style: italic;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Neue Haas Unica', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Neue Haas Unica;
}
