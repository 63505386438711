.creatorPortalContainer {
  max-width:100%;
  height: 100vh;
  background: #121317;
}

.creatorForm-Container {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.creatorUploadContainer {
  margin-top: 62px;
}
