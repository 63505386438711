.createPasswordModal {
  display: flex;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 50%;
  position: absolute;
  justify-content: center;
  z-index: 2147483647;
}

.createPasswordContainer {
  display: flex;
  flex-direction: column;
  width: 475px;
  height: 512px;
  background: #121317;
  box-shadow: 0px 0px 4px #787878;
  border-radius: 10px;
}

.createPasswordCopyContainer:hover {
  background: #787878;
  color: #FFFFFF;
}

.createPasswordHeader {
  font-family: Neue Haas Unica;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 475px;
  text-align: center;
  color: #FFFFFF;
}

.createPasswordHeaderSubText {
  font-family: Neue Haas Unica;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #787878;
  width: 475px;
  margin-top: 16px;
  justify-content: center;
}

.createPasswordbackedupSelctionContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 220px;
  cursor: pointer;
}

.createPasswordbackedupText {
  color: #FFFFFF;
  font-family: Neue Haas Unica;
  font-style: normal;
  font-weight: 800;
  font-size: 26px;
  line-height: 26px;
}


.createPasswordbackedupNewbackedupButton:hover {
  background: #27B2B4;
}


.passwordInput {
  padding: 8px;
  width: 328px;
  height: 40px;
  background: #181818;
  box-shadow: 0px 0px 2px #787878;
  border: none;
  margin-top: 16px;
  color: #FFFFFF;
}

.passwordFormContainer {
  display: flex;
  flex-direction: column;
}

.passwordFormMainContainer {
  display: flex;
  flex-direction: column;
  height: 150px;
  margin-top: 80px;
  justify-content: center;
  align-items: center;
}

.createPasswordSubmit {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 432px;
  height: 60px;
  background: #333333;
  border-radius: 10px;
  border: none;
  color: #FFFFFF;
  font-family: Neue Haas Unica;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
}

.passwordInput:focus {
  outline: none;
}

.createPasswordSubmit:hover {
  background: linear-gradient(91.24deg, #6D33B6 -2.96%, #1962EF 97.8%);
  cursor: pointer;
}

.passwordFormSubmitButton {
  display: flex;
  justify-content: center;
  height: 220px;
  margin-top: 48px;
}

.createPasswordHeaderContainer {
  width: 475px;
  display: flex;
  justify-content: flex-end;
  height: 40px;
}

.createPasswordModalXButton {
  color: #FFFFFF;
  cursor: pointer;
  padding: 16px;
}

.passwordSubmitButtonContainer {
  margin-top: 30px;
}
